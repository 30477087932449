<template>
    <div class="form_grp">
        <div class="group_item">
            <label class="input_label">Send Forms</label>
            <div class="field_wpr">
                <Multiselect v-model="forms.value" v-bind="forms" id="multi_forms"></Multiselect>
            </div>
        </div>
    </div>
    <label v-if="isPortal" for="formshow" class="switch_option capsule_btn">
        <h5>Show in Client Portal<span>Phasellus nec eros nec leo pharetra volutpat. Cras finibus enim sit amet elit ornare blandit.</span></h5>
        <input type="checkbox" id="formshow" v-model="clientPortal"  hidden>
        <div><span></span></div>
    </label>
</template>

<script>
import Multiselect from '@vueform/multiselect';
export default {
    name: 'FormComp',
    props:{
        isPortal: Boolean,
    },
    components:{
        Multiselect
    },
    data(){
        return{
            clientPortal: false,
            forms: {
                mode: 'tags',
                value: [],
                options: [
                    { value: 'Form 1', label: 'Form 1' },
                    { value: 'Form 2', label: 'Form 2' },
                ],
                searchable: true
            },
        }
    }
}
</script>