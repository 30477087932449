<template>
    <div class="form_grp">
        <div class="group_item">
            <label class="input_label">Select Pages</label>
            <div class="field_wpr">
                <Multiselect v-model="pages.value" v-bind="pages" id="multi_pages" placeholder="Select"></Multiselect>
            </div>
        </div>
    </div>
    <label v-if="isPortal" for="pageshow" class="switch_option capsule_btn">
        <h5>Show in Client Portal<span>Phasellus nec eros nec leo pharetra volutpat. Cras finibus enim sit amet elit ornare blandit.</span></h5>
        <input type="checkbox" id="pageshow" v-model="clientPortal" hidden>
        <div><span></span></div>
    </label>
</template>

<script>
import Multiselect from '@vueform/multiselect';
export default {
    name: 'PageComp',
    props:{
        isPortal: Boolean,
    },
    components:{
        Multiselect
    },
    data(){
        return{
            clientPortal: false,
            pages: {
                mode: 'tags',
                value: [],
                options: [
                    { value: 'Page 1', label: 'Page 1' },
                    { value: 'Page 2', label: 'Page 2' },
                ],
                searchable: true
            },
        }
    }
}
</script>