<template>
    <label for="sms_attachment" class="switch_option capsule_btn">
        <h5>Switch to Upload A New File<span>Phasellus nec eros nec leo pharetra volutpat. Cras finibus enim sit amet elit ornare blandit.</span></h5>
        <input type="checkbox" v-model="fileAdd" id="sms_attachment" hidden>
        <div><span></span></div>
    </label>
    <div v-if="fileAdd" class="upload_image">
        <label for="file">                   
            <input type="file" @change="fileSelected" id="file" hidden>
            <img src="../assets/images/image2.png">
            <h4>Click to Upload</h4>
            <p>or drag and drop SVG, PNG, JPG or GIF</p>
        </label>
    </div>
    <div class="form_grp">
        <div class="group_item">
            <label class="input_label">Select From Master Files</label>
            <div class="field_wpr">
                <Multiselect v-model="files.value" v-bind="files" id="multi_files"></Multiselect>
            </div>
        </div>
    </div>
    <ul v-if="isList" class="file_listing">
        <li v-for="item in files.options" :key="item.label">
            <div class="file_wpr">
                <div class="file_thumb">
                    <img :src="require(`@/assets/images/${item.img}`)">
                    <button class="delete_btn"><i class="fas fa-trash-alt"></i></button>
                    <button class="view_btn" @click="fileDetails = true"><i class="fas fa-binoculars"></i>View</button>
                </div>
                <div class="file_info">
                    <h4>{{item.label}}<span>Show in Portal</span></h4>
                    <label :for="contacts" class="switch_option capsule_btn">
                        <input type="checkbox" :id="contacts" hidden>
                        <div><span></span></div>
                    </label>
                </div>
            </div>
        </li>
    </ul>
    <div v-if="fileDetails" class="file_details" @click.self="fileDetails = false">
        <FileInfo/>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import FileInfo from './FileInfo.vue';
export default {
    name: 'PageComp',
    props:{
        isList: Boolean,
    },
    components:{
        Multiselect,
        FileInfo
    },
    data(){
        return{
            fileAdd: false,
            fileDetails: false,
            files: {
                mode: 'tags',
                value: [],
                options: [
                    { value: 'Files 1', label: 'Files 1', img: 'file1.png' },
                    { value: 'Files 2', label: 'Files 2', img: 'file1.png'},
                ],
                searchable: true,
                createTag: true
            },
        }
    }
}
</script>