<template>
    <ul class="template_list">
        <li>
            <div class="card_item" @click="tempSelect($event)"><img src="@/assets/images/mail.svg">Email</div>
        </li>
        <li>
            <div class="card_item" @click="tempSelect($event)"><img src="@/assets/images/sms.svg">SMS</div>
        </li>
        <li>
            <div class="card_item" @click="tempSelect($event)"><img src="@/assets/images/playbook.svg">Voice</div>
        </li>
        <li>
            <div class="card_item" @click="tempSelect($event)"><img src="@/assets/images/template.svg">Multi-Channel</div>
        </li>
    </ul>
    <div class="form_grp">
        <div class="group_item">
            <label class="input_label">Category</label>
            <div class="field_wpr">
                <select v-model="category">
                    <option disabled selected hidden>Select Category</option>
                    <option value="Affiliate">Affilliate</option>
                    <option value="Ascension">Ascension</option>
                    <option value="Awareness">Awareness</option>
                    <option value="Daily Email">Daily Email</option>
                    <option value="Direct Pitch">Direct Pitch</option>
                    <option value="Engagement">Engagement</option>
                    <option value="Holiday">Holiday</option>
                    <option value="Indirect Pitch">Indirect Pitch</option>
                    <option value="Indoctrination">Indoctrination</option>
                    <option value="One-Off">One-Off</option>
                    <option value="Re-engagement">Re-engagement</option>
                    <option value="Segmentation">Segmentation</option>
                    <option value="Showcase">Showcase</option>
                    <option value="Special Offer">Special Offer</option>
                    <option value="Useful But Incomplete">Useful But Incomplete</option>
                    <option value="Unassigned">Unassigned</option>
                </select>
                <span class="icon"><i class="fas fa-angle-down"></i></span>
            </div>
        </div>
    </div>
    <div v-if="category" class="tempcard">
        <ul>
            <li>
                <label for="tempcard1" class="checkbox">
                    <input type="checkbox" id="tempcard1" hidden>
                    <span><i class="fas fa-check"></i></span>
                </label>
                <div class="tempcard_details">
                    <h5>LBO Customer Welcome Letter</h5>
                    <p><i class="fas fa-envelope"></i>Maecenas fringilla imperdiet hendrerit.</p>
                </div>
                <span class="view"><i class="fas fa-search-plus"></i></span>
            </li>
            <li>
                <label for="tempcard2" class="checkbox">
                    <input type="checkbox" id="tempcard2" hidden>
                    <span><i class="fas fa-check"></i></span>
                </label>
                <div class="tempcard_details">
                    <h5>LBO Customer Welcome Letter</h5>
                    <p><i class="fas fa-envelope"></i>Suspendisse dapibus tellus non eleifend.</p>
                </div>
                <span class="view"><i class="fas fa-search-plus"></i></span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'TemplateComp',
    data(){
        return{
            category: '',
        }
    },
    methods:{
        tempSelect(e){
            let temp = e.currentTarget;
            let allTemp = temp.closest('.template_list').querySelectorAll('.card_item');
            for(let i = 0; i < allTemp.length; i++){
                allTemp[i].classList.remove('active');
            }
            if(temp.classList.contains('active')){
                temp.classList.remove('active');
            }else{
                temp.classList.add('active');
            }
        }
    }
}
</script>