<template>
    <div class="form_grp">
        <div class="group_item">
            <label class="input_label">Journey</label>
            <div class="field_wpr">
                <Multiselect v-model="journey.value" v-bind="journey"></Multiselect>
            </div>
        </div>
    </div>
    <label v-if="journey.value.length == !null && isStep" for="firststep" class="switch_option capsule_btn">
        <h5>Start at First Step?<span>Phasellus nec eros nec leo pharetra volutpat. Cras finibus enim sit amet elit ornare blandit.</span></h5>
        <input type="checkbox" id="firststep" v-model="step1" hidden>
        <div><span></span></div>
    </label>
    <div v-if="!step1" class="form_grp">
        <div class="group_item">
            <label class="input_label">Assign Step</label>
            <div class="field_wpr">
                <Multiselect v-model="journeyStep.value" v-bind="journeyStep"></Multiselect>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
export default {
    name: 'JourneyComp',
    components:{
        Multiselect
    },
    props:{
        isStep: Boolean,
    },
    data(){
        return{
            step1: true,
            journey: {
                mode: 'tags',
                value: [],
                options: [
                    { value: 'Test Journey', label: 'Test Journey'}
                ],
                searchable: true
            },
            journeyStep:{
                mode: 'single',
                value: [],
                options: [
                    { value: 'Step 1', label: 'Step 1 (Assign Playbook)'}
                ],
                searchable: true
            }
        }
    }
}
</script>
