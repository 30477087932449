<template>
    <div class="setting_wpr">
        <div class="section_wpr">
            <h3>Connection Tools <button type="button" @click="editNote = true">Add Note</button></h3>
            <ul>
                <li>
                    <div class="card_item" @click="smsItem = true"><img src="@/assets/images/sms.svg">Send SMS</div>
                </li>
                <li>
                    <div class="card_item" @click="emailItem = true"><img src="@/assets/images/mail.svg">Send Email</div>
                </li>
                <li>
                    <div class="card_item" @click="tempItem = true"><img src="@/assets/images/template.svg">Send Template</div>
                </li>
            </ul>
        </div>
        <div v-if="editNote" class="edit_section mb-4">
            <div class="section_title mb-4">Add Note 
                <div class="dropdown">
                    <i class="fas fa-ellipsis-v" @click="noteOption = !noteOption"></i>
                    <div class="drp_wrapper" v-if="noteOption" :class="{active : noteOption}">
                        <ul>
                            <li>Reset to default message</li>
                            <li>Import to save message</li>
                            <li>Send a test email</li>
                            <li>Preview in browser</li>
                            <li>Save as template</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="editorWpr"><img src="@/assets/images/editor.png"></div>
            <div class="action_wpr mt-4">
                <button class="btn cancel_btn" @click="editNote = false">Cancel</button>
                <button class="btn save_btn">Save</button>
            </div>
        </div>
        <div class="section_wpr">
            <h3>Transformation Tools</h3>
            <ul>
                <li>
                    <div class="card_item" @click="playbookItem = true"><img src="@/assets/images/playbook.svg">Assign Playbook</div>
                </li>
                <li>
                    <div class="card_item" @click="pagesItem = true"><img src="@/assets/images/page.svg">Send Page</div>
                </li>
                <li>
                    <div class="card_item" @click="formsItem = true"><img src="@/assets/images/form.svg">Send Form</div>
                </li>
                <li>
                    <div class="card_item" @click="filesItem = true"><img src="@/assets/images/folder2.svg">Add File</div>
                </li>
                <li>
                    <div class="card_item" @click="rewardsItem = true"><img src="@/assets/images/reward.svg">Add Rewards</div>
                </li>
                <li>
                    <div class="card_item" @click="journeyItem = true"><img src="@/assets/images/journey.svg">Start Journey</div>
                </li>
            </ul>
        </div>
        <div v-if="smsItem" class="details_wpr" @click.self="closeBar()">
            <div class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="smsItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Send SMS</h2>
                </div>
                <SmsComp isContact/>
                <div class="action_wpr mt-5">
                    <button class="btn cancel_btn" @click="smsItem = false">cancel</button>
                    <button class="btn save_btn">Send</button>
                </div>                
            </div>
        </div>
        <div v-if="emailItem" class="details_wpr" @click.self="closeBar()">
            <div class="cardItem_details" @scroll="scrollFunc($event)">
                <div class="header">
                    <button class="close_btn" @click="emailItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Send Email</h2>
                </div>
                <EmailComp isRecipient/>
                <div class="action_wpr mt-5">
                    <button class="btn cancel_btn" @click="emailItem = false">Cancel</button>
                    <button class="btn save_btn">Send</button>
                </div>
                <span :class="scrollStatus ? 'scroll_indicator swing' : 'scroll_indicator'"><i class="fas fa-long-arrow-alt-up"></i></span>
            </div>
        </div>
        <div v-if="tempItem" class="details_wpr" @click.self="closeBar()">
            <div class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="tempItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Send Templates</h2>
                </div>
                <TemplateComp/>
                <div class="action_wpr mt-5">
                    <button class="btn cancel_btn" @click="tempItem = false">Cancel</button>
                    <button class="btn save_btn">Send</button>
                </div>
            </div>
        </div>
        <div v-if="playbookItem" class="details_wpr" @click.self="closeBar()">
            <div class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="playbookItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Assign Playbooks</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Select My Playbooks</label>
                        <div class="field_wpr">
                            <Multiselect v-model="playbooks.value" v-bind="playbooks" id="multi_playbooks"></Multiselect>
                        </div>
                    </div>
                </div>
                <h3>Sending Method</h3>
                <div class="edit_section">
                    <NotifyMethods/>
                </div>
            </div>
        </div>
        <div v-if="pagesItem" class="details_wpr" @click.self="closeBar()">
            <div class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="pagesItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Send Pages</h2>
                </div>
                <PageComp isPortal />
                <h3>Notification Method</h3>
                <div class="edit_section">
                    <NotifyMethods/>
                </div>
                <div class="action_wpr mt-5">
                    <button class="btn cancel_btn" @click="pagesItem = false">Cancel</button>
                    <button class="btn save_btn">Send</button>
                </div>
            </div>
        </div>
        <div v-if="formsItem" class="details_wpr" @click.self="closeBar()">
            <div class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="formsItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Send Forms</h2>
                </div>
                <FormComp isPortal/>
                <h3>Sending Method</h3>
                <div class="edit_section">
                    <NotifyMethods/>
                </div>
                <div class="action_wpr mt-5">
                    <button class="btn cancel_btn" @click="formsItem = false">Cancel</button>
                    <button class="btn save_btn">Send</button>
                </div>
            </div>
        </div>
        <div v-if="filesItem" class="details_wpr" @click.self="closeBar()">
            <div class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="filesItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Add A File</h2>
                </div>
                <FileComp isList/>
                <div class="action_wpr mt-5">
                    <button class="btn cancel_btn" @click="filesItem = false">Cancel</button>
                    <button class="btn save_btn">Add</button>
                </div>
            </div>
        </div>
        <div v-if="rewardsItem" class="details_wpr" @click.self="closeBar()">
            <div class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="rewardsItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Add Points</h2>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Points Awarded</label>
                        <div class="field_wpr">
                            <input type="number" min="0" v-model="points">
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">You Just Earn {{points}} Points For</label>
                        <div class="field_wpr">
                            <input type="text" placeholder="Type Here...">
                        </div>
                    </div>
                </div>
                <label for="allow" class="switch_option capsule_btn">
                    <h5>Contact Notification<span>Praesent non lectus eu augue ornare faucibus. Sed porta id nisl id eleifend.</span></h5>
                    <input type="checkbox" id="allow" v-model="allowNotification" hidden>
                    <div><span></span></div>
                </label>
                <div v-if="allowNotification">
                    <h3>Notification Method</h3>
                    <div class="edit_section">
                        <NotifyMethods/>
                    </div>
                </div>
                <div class="action_wpr mt-5">
                    <button class="btn cancel_btn" @click="rewardsItem = false">Cancel</button>
                    <button class="btn save_btn">Add</button>
                </div>
            </div>
        </div>
        <div v-if="journeyItem" class="details_wpr" @click.self="closeBar()">
            <div class="cardItem_details">
                <div class="header">
                    <button class="close_btn" @click="journeyItem = false"><i class="fas fa-chevron-down"></i></button>
                    <h2>Start Journey</h2>
                </div>
                <JourneyComp isStep/>
                <div class="action_wpr mt-5">
                    <button class="btn cancel_btn" @click="journeyItem = false">Cancel</button>
                    <button class="btn save_btn">Start</button>
                </div>
            </div>
        </div>
        <div class="section_wpr">
            <h3>Active Tags</h3>
            <ul class="active_tags">
                <li class="system"><span>System Tags</span></li>
                <li class="id_tags"><span>ID Tags</span></li>
                <li class="trigger"><span>Email Enabled</span></li>
                <li class="trigger"><span>SMS Enabled</span></li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect';
    import NotifyMethods from './NotifyMethods.vue';
    import SmsComp from './SmsComp.vue';
    import EmailComp from './EmailComp.vue';
    import JourneyComp from './JourneyComp.vue';
    import TemplateComp from './TemplateComp.vue';
    import FormComp from './FormComp.vue';
    import PageComp from './PageComp.vue';
    import FileComp from './FileComp.vue';
    export default {
        name: "Actions",
        components: {
            Multiselect,
            NotifyMethods,
            EmailComp,
            SmsComp,
            JourneyComp,
            TemplateComp,
            FormComp,
            PageComp,
            FileComp
        },
        data(){
            return{
                editNote: false,
                noteOption : false,
                smsItem: false,                
                emailItem: false,           
                tempItem: false,                
                playbookItem: false,
                pagesItem: false,
                formsItem: false,                
                filesItem: false,
                rewardsItem: false,
                points: '0',
                journeyItem: false,                
                allowNotification: true,
                scrollStatus: true,
                playbooks: {
                    mode: 'tags',
                    value: [],
                    options: [
                        { value: 'Playbooks 1', label: 'Playbooks 1' },
                        { value: 'Playbooks 2', label: 'Playbooks 2' },
                    ],
                    searchable: true,
                    createTag: true
                },
            }
        },
        methods:{
            scrollFunc(e){
                let z = e.currentTarget;
                let a = z.clientHeight + z.scrollTop;
                let x = z.scrollHeight;
                if(a == x){
                    this.scrollStatus = false;
                }else{
                    this.scrollStatus = true;
                }
            },
            closeBar(){
                this.smsItem = false;
                this.emailItem = false;
                this.tempItem = false;
                this.playbookItem = false;
                this.pagesItem = false;
                this.formsItem = false;
                this.filesItem = false;
                this.rewardsItem = false;
                this.journeyItem = false;
            }            
        }
    }
</script>